@import url("https://fonts.cdnfonts.com/css/bookman-old-style");
@import url("https://fonts.googleapis.com/css?family=Poppins:400");
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");

:root {
  /* primary   color*/
  --primary--100: #ede9fe;
  --primary--200: #ddd6fe;
  --primary--300: #c4b5fd;
  --primary--400: #a78bfa;
  --primary--500: #8b5cf6;
  --primary--600: #7c3aed;
  --primary--700: #6d28d9;
  --primary--800: #5b21b6;
  --primary--900: #4c1d95;

  /* new colors */
  --primary-color: #5c57a0;
  --secondary-color: #e6e4f3;
  --tertiary-color: #0d113c;
  --quaternary-color: #acacce;

  /* box shawdoing color */

  --gray--100: #f3f4f6;
  --gray--200: #e5e7eb;
  --gray--300: #d1d5db;
  --gray--400: #9ca3af;
  --gray--500: #6b7280;
  --gray--600: #4b5563;
  --gray--700: #374151;
  --gray--800: #1f2937;
  --gray--100: #111827;

  /*for footer*/
  --white: #fff;
  --black: #000;
  --dark: #1e1e1e;
  --gray: rgba(1, 1, 1, 0.6);
  --lite: rgba(255, 255, 255, 0.6);
  --primary: #002347;
  --secondary: #fdc632;

  /* fontfamily */
  --font-family: "Bookman Old Style", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family) !important;
}

p {
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 400 !important;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text_small {
  font-size: 0.8rem;
}
/* anchor tag universal css remover */
a {
  text-decoration: none;
  color: inherit;
}

/* list universal css remover */
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.component-title {
  text-decoration: underline;
  color: var(--tertiary-color);
  font-size: 2rem !important;
  font-weight: 600 !important;
  text-align: center !important;
}

.component-text {
  color: var(--tertiary-color);
  font-size: 1.2rem !important;
}

.emphasis {
  /* color: white; */
  font-weight: 600;
  font-size: 1.2rem;
}

.upgrading-text {
  color: red;
  text-align: center;
  margin: 10px 0 !important;
  user-select: none;
}
.upgrading-text:hover {
  color: red;
  cursor: pointer;
}

.depricated-text {
  font-size: 0.8rem;
}

.ani-list:hover {
  transition: all 200ms;
  transform: translateX(5px);
}

.border-bottom {
  border-bottom: 2px solid var(--primary--900) !important;
}

button.action-btn {
  background-color: var(--primary--700);
  letter-spacing: 2px;
  font-size: 1.2rem;
}

button.action-btn:hover {
  background-color: var(--primary--200);
  color: var(--primar);
  border: 1px solid var(--primary--500);
}
